import React from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
// import Footer from "./Footer1";
import TopBar from "src/layouts/HomeLayout/TopBar.js";
import Footer from "../LoginLayout1/Footer1";

const useStyles = makeStyles((theme) => ({
  root: {

    fontFamily:"Inter",
 
    "@media (min-width: 1326px)": {
      "& .MuiContainer-root":{
        paddingLeft:"0",
        paddingRight:"0"
      }
    },
  },
  MainLayout:{
    minHeight:"calc(100vh - 415px)"
  },
}));

const TermsCondLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  console.log(history.location);
  return (
    <div className={classes.root}>
      {/* <TopBar /> */}
      {/* <div
        style={
          history.location?.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      >
    
      </div> */}

      <div className={classes.MainLayout}>{children}</div>
      {/* <Footer /> */}
      {/* <Footer/> */}
    </div>
  );
};

export default TermsCondLayout;
