import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TopBar from "src/layouts/HomeLayout/TopBar.js";
import Footer from "../LoginLayout1/Footer1";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: "none",
    height: "100vh",

    marginTop: "4%",
  },
  content1: {
    width: "510px",
    marginTop: "17%",
    borderRadius: "8px",

    // background: "#F4F8FE",
    background: "linear-gradient(180deg, rgba(143, 143, 143, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(137.09deg, rgba(42, 42, 42, 0.2) 0%, rgba(0, 164, 239, 0.14) 55.73%, rgba(42, 42, 42, 0.2) 100%)",
    border: "1px solid #FFFFFF",
    backdropFilter: "blur(2px)",
    borderRadius: "8px",

    height: "auto",
    maxHeight: "45rem",

    color: "#000",
  },
  left: {
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  mainClass: {
    marginLeft: "20%",
    [theme.breakpoints.only("lg")]: {
      maxWidth: "665px",
      marginLeft: "70px",
    },
    [theme.breakpoints.only("md")]: {
      paddingLeft: "40px",
    },
  },

  mainbox: {
    width: "580px",
    height: " 580px",
    marginTop: "12%",
    marginLeft: "-3%",

    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  mainbox1: {
    marginTop: "18%",
    marginLeft: "-6%",
    width: "639px",
    height: " 450px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  root: {
    backgroundImage: "url(images/backgroundLogin.svg)",

    backgroundSize: "cover",
    backgroundColor: "#2D2D2D",

    "@media (min-width: 1326px)": {
      "& .MuiContainer-root": {
        paddingLeft: "0",
        paddingRight: "0",
      },
    },
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.root}>
        <div className={classes.Top}>
          <TopBar />
        </div>

        <Container maxWidth="lg" 
        style={{ 
          paddingBottom: "241px" 
          }}>
          <Grid container style={{ justifyContent: "center" }}>
            <Box className={classes.content}>
              <Box className={classes.content1}>{children}</Box>
            </Box>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
