import React from "react";
import { Box, Container, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TopBar from "../../../layouts/HomeLayout/TopBar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Page from "../../../component/Page";
import { getAbout } from "../../../service/auth";
const useStyle = makeStyles((theme) => ({
  root: {
    background: "#414141",
    // backgroundColor: `linear-gradient(174.64deg, #414141 100%, #004D78 100%)`,
    width: "100%",
    backgroundSize: "cover",
    backgroundImage: "url(images/backgroundimg.svg)",
    imageOpacity: "0.2",
    "@media (min-width: 1326px)": {
      "& .MuiContainer-root": {
        paddingLeft: "0",
        paddingRight: "0",
      },
    },
  },
  termsHeading: {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "36px",
    color: "#FFFFFF",
    marginTop: "90px",
    marginLeft:"36px",
  },
  termsPara: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#FFFFFF",
    textAlign: "justify",
  },
  Arrow: {
    fontSize: "41px !important",
    color: "#FFFFFF !important",
    cursor: "pointer",
  },
}));

export default function About() {
  const classes = useStyle();
  let history = useHistory();
  const [about, setAbout] = React.useState();
  // get about data integration
  const getAboutData = async () => {
    try {
      const res = await getAbout();
      setAbout(res.data.Data[0]);
    } catch (error) {}
  };
  React.useEffect(() => {
    getAboutData();
  }, []);

  return (
    <Page title="About us">
      <div
        style={{
          background: "#414141",
          width: "100%",
          backgroundSize: "cover",
          backgroundImage: "url(images/backgroundimg.svg)",
          imageOpacity: "0.2",
        }}
      >
        <TopBar style={{ marginLeft: "-5%", marginTop: "10%" }} />

        <Box style={{ marginTop: "2%",paddingBottom:"10%" }}>
          <Container>
            {/* <Box mt={8} px={4} style={{ display: "flex" }}> */}
              {/* <ArrowBackIcon
                onClick={() => history.push("/register")}
                className={classes.Arrow}
              /> */}
              <Typography className={classes.termsHeading}>
                About Us
              </Typography>
            {/* </Box> */}
            <Box mt={6} mb={5} px={4}></Box>
            <Box style={{ paddingBottom: "25rem" }} px={4}>
              <Typography className={classes.termsPara}>
                {about?.description}
              </Typography>
            </Box>
          </Container>
        </Box>
      </div>
    </Page>
  );
}
