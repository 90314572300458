import React from "react";
import { Box, Container, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TopBar from "src/layouts/TermsPrivacyLayout/topbar.js";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Page from "../../../component/Page";
import { getPolicy } from "../../../service/auth";

const useStyle = makeStyles((theme) => ({
  root: {
    background: "#414141",
    // backgroundColor: `linear-gradient(174.64deg, #414141 100%, #004D78 100%)`,
    width: "100%",
    backgroundSize: "cover",
    backgroundImage: "url(images/backgroundimg.svg)",
    imageOpacity: "0.2",
    "@media (min-width: 1326px)": {
      "& .MuiContainer-root": {
        paddingLeft: "0",
        paddingRight: "0",
      },
    },
  },
  termsHeading: {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "36px",
    color: "#FFFFFF",
    marginTop: "90px",
  },
  termsPara: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#FFFFFF",
    textAlign: "justify",
  },
  // Arrow: {
  //   fontSize: "41px !important",
  //   color: "#FFFFFF !important",
  //   cursor: "pointer",
  // },
}));

export default function Index() {
  const classes = useStyle();
  let history = useHistory();
  const [policy, setPolicy] = React.useState();
  const getPolicyData = async () => {
    try {
      const res = await getPolicy();
      setPolicy(res.data.Data[0]);
    } catch (error) {}
  };
  React.useEffect(() => {
    getPolicyData();
  }, []);

  return (
    <Page title="Privacy policy">
      <div
        style={{
          background: "#414141",
          width: "100%",
          backgroundSize: "cover",
          backgroundImage: "url(images/backgroundimg.svg)",
          imageOpacity: "0.2",
        }}
      >
        <TopBar style={{ marginLeft: "-5%", marginTop: "10%" }} />

        <Box style={{ marginTop: "2%" }}>
          <Container>
            <Box mt={8} px={4} style={{ display: "flex" }}>
              {/* <ArrowBackIcon
                onClick={() => history.push("/register")}
                className={classes.Arrow}
              /> */}
              <Typography className={classes.termsHeading}>
                Privacy Policy
              </Typography>
            </Box>
            <Box mt={6} mb={5} px={4}></Box>
            <Box style={{ paddingBottom: "25rem" }} px={4}>
              <Typography className={classes.termsPara}>
                {policy?.description}
              </Typography>
            </Box>
          </Container>
        </Box>
      </div>
    </Page>
  );
}
