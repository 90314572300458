import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import { Box, Typography, makeStyles } from "@material-ui/core";

import { useHistory } from "react-router-dom";
// ClosedButton: {
//   width: "102px",
//   height: "41px",
//   background: "#00A4EF",
//   borderRadius: "8px",
//   borderRadius: "5px !important",
//   padding: "12px 19px !important",
//   fontSize: "18px !important",
//   border: " 3px solid #00A4EF",
//   "&:hover": {
//     background: "#fff",
//     color: "#00A4EF",
//     border: " 3px solid #00A4EF",
//   },
const useStyles = makeStyles((theme) => ({
  ClosedButton: {
    width: "102px",
    height: "41px",
    background: "#00A4EF",
    borderRadius: "8px",
    borderRadius: "5px !important",
    padding: "12px 19px !important",
    fontSize: "18px !important",
    border: " 3px solid #00A4EF",
    "&:hover": {
      background: "#fff",
      color: "#00A4EF",
      border: " 3px solid #00A4EF",
    },
  },
  ClosedButton1: {
    background: "#fff",
    color: "#00A4EF",
    border: " 2px solid #00A4EF",
    width: "102px",
    height: "41px",
    borderRadius: "8px",
    borderRadius: "5px !important",
    padding: "12px  19px  !important",
    fontSize: "18px !important",
    marginLeft: "4rem",
    "&:hover": {},
  },
  SendButton: {
    background: "#636363",
    borderRadius: "5px !important",
    padding: "7px 19px !important",
    fontSize: "12px !important",

    "&:hover": {
      background: "#fff",
      color: "#000",
    },
  },
  title:{
    marginTop:"45px",
        textAlign: 'center',
              fontFamily: 'Inter',
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "22px",
              color:" #000000",
  },
  logout:{  
    
      width:"363px",
      height:"211px",
      borderRadius:"8px",
    }
}));

export default function AlertDialog({
  open,
  handleClose,
  title,
  desc,
  confirmationHandler,
}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      >
        <Box  className={classes.logout}>

   
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
          
            className={classes.title}
            >
              Do you want to logout?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box
            // style={{
            //   display: "flex !important",
            //   justifyContent: "center !important",
            //   paddingBottom: "30px",
            // }}
          >
            <Button
              variant="contained"
              className={classes.ClosedButton}
              to="/login"
              onClick={() => {
                confirmationHandler();
                handleClose();

              }}
            >
              Yes
            </Button>
            <Button
              className={classes.ClosedButton1}
              onClick={handleClose}
              autoFocus
            >
              No
            </Button>
          </Box>
        </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}
