// // Base URL
// let baseurl="https://dexwin-api.herokuapp.com";
let baseurl="http://182.72.203.249:3011/api/v1";
const apiConfig = {

    serverIPAddress: `${baseurl}/wallet/serverIPAddress`,


}

export default apiConfig;