import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(20px)",
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    }
  },
  paper: {
    width: "267px",
    height: "auto",
    background: "linear-gradient(180deg, rgba(143, 143, 143, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(137.09deg, #2A2A2A 0%, rgba(0, 0, 0, 0.9) 55.73%, #2A2A2A 100%)",
    border: "none",
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    padding: "2rem",
  },

  icon: {
    display: "flex",
    justifyContent: "center",
  },
  message: {
    display: "flex",
    justifyContent: "center",
    paddingTop:"7px"
  },
  messageContent:{
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "130%",
    textAlign: "center",
    color: "#FFFFFF",
  }
}));

export default function CustomToast(props) {
  const { type, open, handleClose, message } = props;
  const classes = useStyles();
  //   const [open, setOpen] = React.useState(false);

  //   const handleOpen = () => {
  //     setOpen(true);
  //   };
  console.log(type, open);
  const handleCloseModal = () => {
    handleClose(false);
  };
  console.log(open, "open");
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.icon}>
              {type === "success" ? (
                <img src="images/success.svg"></img>
              ) : type === "error" ? (
                <img src="images/error.svg"></img>
              ) : (
                <img src="images/warn.svg" />
              )}
            </div>
            <div className={classes.message}>
              <p id="transition-modal-description" className={classes.messageContent}>
                {message}
              </p>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
