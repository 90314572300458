import React, { useState, useContext } from "react";
import "src/scss/main.css";
import { Box, Typography, Grid, makeStyles, Button } from "@material-ui/core";
import Logo from "src/component/Logo";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import {
  useHistory,
  Link as RouterComponent,
  useLocation,
} from "react-router-dom";
import { calculateTimeLeft } from "src/views/Auth/forget-password-link/timer";
import TopBar from "src/layouts/LoginLayout/index.js";
import Page from "src/component/Page";
import ToastMessage from "src/component/customToast";
import axios from "axios";
import { AuthContext } from "src/context/Auth";
import OtpInput from "react-otp-input";
import moment from "moment";
import { BASE_URL } from "../../../config/APICongig";

// const useQuery = (): URLSearchParams => {
//   return new URLSearchParams(useHistory().search)
// }

const useStyles = makeStyles((theme) => ({
  logosec: {
    "@media(min-width:1280px)": {
      display: "none",
    },
  },
  textfiled: {
    width: "10px",
    height: "40px",
    background: "rgba(217, 217, 217, 0.1)",
    border: "1px solid #D4D4D4",
    borderRadius: "3px",
  },
  submit: {
    width: "374.3px",
    height: "50px",
    background: "#00A4EF",
    marginLeft: "12.5%",
    borderRadius: "7px",
    marginBottom: "3rem",
    marginTop: "-1rem",
  },

  forgot: {
    display: "flex",
    justifyContent: "flex-end",
  },
  OtpDiv: {
    marginTop: "2rem",
    paddingBottom: "3rem",
    paddingLeft: "15px",
  },
  Link: {
    textDecoration: "none",
    fontSize: "18px",
    float: "right",
    paddingRight: "18px",
    marginBottom: "9px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: " 700",
    fontSize: "10px",
    lineHeight: "12px",
    letterSpacing: "0.06em",
    color: "#000000",
  },
  inputBlocks: {
    paddingLeft: "27px",
    PaddingTop: "45px",
  },
  inputFields: {
    color: "#ffff",
  },
  timer: {
    marginRight: "25px",
    paddingLeft: "11.5rem",
    color: "#000000",
  },
  TimerExpired: {
    color: "#E03128",
    marginRight: "18px",
    paddingLeft: "13rem",
    cursor: "pointer",
    fontSize: "16px !important",
  },
  timerGrid: {
    padding: "1rem 1.3rem",
    paddingBottom: "0px",
    display: "flex",
    textAlign: "right",
    gap: "9rem",
  },
  error: {
    color: "#E03128",
    fontSize: "94%",
  },
  inputBox: {
    paddingLeft: "13%",
    paddingTop: "2.5rem",
  },
}));

function Login(props) {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const [isLoading, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [code, setCode] = useState("");
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(false);
  const [message, setMessage] = useState("");

  // const query = useQuery();
  // const data = query.get("response")
  // console.log(data,"data")

  const location = useLocation();
  console.log(location, "data");

  const handleClose = (x) => {
    setOpen(x);
  };

  const handleChange = (code) => {
    setCode(code);
    if (code.length == 6) {
      setError("");
    }
  };

  const handleFormSubmit = async () => {
    const userEmail = localStorage.getItem("email");
    if (code.length == 6) {
      console.log("code length", code.length);

      try {
        const res = await axios({
          method: "POST",
          url: `${BASE_URL}/signUpOTPVerfiy`,

          data: {
            email: userEmail,
            OTP: code,
          },
        });

        console.log(res, "signUpOtp");

        if (res.data.success) {
          setLoader(false);
          setType("success");
          setOpen(true);
          setMessage("Account created successfully.");
          setTimeout(() => {
            history.push("/login");
          }, 3000);
        } else {
          setType("error");
          setOpen(true);
          setMessage(res.data.message);
          setTimeout(() => {
            history.push("/verify-otp");
          }, 3000);
        }
      } catch (error) {
        setLoader(false);

        if (error.response) {
          setType("error");
          setOpen(true);
          setMessage(error.response.data.message);
          // toast.error(error.response.data.message);
          setTimeout(() => {
            history.push("/verify-otp");
          }, 3000);
        } else {
          // toast.error(error.message);
        }
      }
    } else {
      setError("Please enter OTP");
    }
  };

  const handleResendOTP = async (values) => {
    console.log(values, "values");
    try {
      const res = await axios.post(`${BASE_URL}/reSentOTP`, {
        email: localStorage.getItem("email"),
      });
      console.log(res.status, "api data");
      if (res.data.success) {
        setType("success");
        setOpen(true);
        setMessage("OTP sent successfully, Please check your email.");
        auth.setEndtime(moment().add(3, "m").unix());
        history.push("/verify-otp");
        console.log(res.data.message);
        auth.setEndtime(moment().add(3, "m").unix());
      } else if (
        res.status === 400 &&
        res.data.message ===
          "OTP is Already Sent To Mail For Resend OTP Wait For 3 Min"
      ) {
        setType("warn");
        setOpen(true);
        setMessage("OTP is Already Sent To Mail For Resend OTP Wait For 3 Min");
      }

      return res;
    } catch (error) {
      console.log(error);

      if (
        error.response.status === 400 &&
        error.response.data.message ===
          "OTP is Already Sent To Mail For Resend OTP Wait For 3 Min"
      ) {
        setType("error");
        setOpen(true);
        setMessage("OTP is Already Sent To Mail For Resend OTP Wait For 3 Min");
        // toast.error(
        //   "OTP is Already Sent To Mail For Resend OTP Wait For 3 Min"
        // );
        // setIsLoading(false);
      }
    }
  };
  // console.log(response,"OTPresponse");
  return (
    <>
      <ToastMessage
        type={type}
        handleClose={handleClose}
        open={open}
        message={message}
      />
      {calculateTimeLeft}
      <TopBar>
        <Page title="forgot">
          <Grid
            className="d-flex height100"
            style={{ width: "auto", height: "auto" }}
          >
            <Box className="loginForm">
              <Box className="signupbox">
                <Grid
                  container
                  direction={"column"}
                  style={{ paddingLeft: "0.5rem" }}
                >
                  <Grid item style={{}}>
                    <Typography
                      variant="h5"
                      style={{
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "30px",
                        lineHeight: "24px",
                        color: "#FFFFFF",
                        paddingTop: "3rem",
                        paddingLeft: "2rem",
                      }}
                    >
                      Verification Code
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "18px",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "12px",
                        color: "#FFFFFF",
                        marginTop: "10%",
                        paddingLeft: "40px",
                      }}
                    >
                      A 6- digit OTP has been sent to your registered e-mail.
                    </Typography>
                  </Grid>{" "}
                  <Grid item>
                    <Box className={classes.logosec}>
                      <Logo width="110" style={{ cursor: "pointer" }} />
                    </Box>
                  </Grid>
                  <Box className={classes.inputBox}>
                    <Grid item style={{}}>
                      <OtpInput
                        value={code}
                        onChange={handleChange}
                        numInputs={6}
                        separator={<span style={{ width: "1.2rem" }}></span>}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        inputStyle={{
                          border: "1px solid #D4D4D4",
                          borderRadius: "5px",
                          width: "40px",
                          height: "40px",
                          fontSize: "20px",
                          color: "#FFFFFF",
                          fontWeight: "400",
                          caretColor: "#fff",
                          background: "rgba(217, 217, 217, 0.1)",
                        }}
                        focusStyle={{
                          border: "2px solid #333",
                          outline: "none",
                        }}
                      />
                    </Grid>
                  </Box>
                  {auth.timeLeft && auth.timeLeft.seconds >= 0 ? (
                    <>
                      <Grid xs={12} className={classes.timerGrid}>
                        <Grid xs={7} style={{ marginLeft: "4%" }}>
                          <Typography className={classes.error}>
                            {error}
                          </Typography>
                        </Grid>
                        <Grid
                          xs={5}
                          style={{ height: "55px" }}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              color: "#FFFFFF",
                            }}
                          >
                            {" "}
                            {auth.timeLeft?.minutes} : {auth.timeLeft?.seconds}
                          </Typography>{" "}
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid xs={12} className={classes.timerGrid}>
                        <Grid xs={7} style={{ marginLeft: "4%" }}>
                          <Typography className={classes.error}>
                            {error}
                          </Typography>
                        </Grid>
                        <Grid xs={5} style={{ height: "55px" }}>
                          <Typography
                            variant="contained"
                            color="secondary"
                            fullWidth
                            style={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "14px",
                              color: "#FF0000",
                              cursor: "pointer",

                              fontWeight: "700",
                              lineHeight: "12px",
                              letterSpacing: "0.06em",
                            }}
                            onClick={handleResendOTP}
                            disabled={
                              auth.timeLeft && auth.timeLeft.seconds > 0
                            }
                          >
                            {" "}
                            Resend OTP{" "}
                          </Typography>{" "}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    type="submit"
                    disabled={isLoading}
                    onClick={() => handleFormSubmit(code)}
                  >
                    Submit
                    {isLoading && <ButtonCircularProgress />}
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Page>
      </TopBar>
    </>
  );
}

export default Login;
