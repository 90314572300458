import React from "react";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyle = makeStyles(() => ({
  root: {
    display: "flex",
    // background: "blue",
    color: "#FFFFFF",
    padding: "30px 60px 10px 60px",

    fontFamily: "Roboto",
    fontStyle: "normal",
  },
  ImageGrid: {},
  middleGrid: {
    display: "flex",
    justifyContent: "center",
    gap: "2rem",
    lineHeight: "130%",
    fontSize: "18px",
    // marginRight: "80px",
    marginTop: "20px",

    fontFamily: "Roboto",
    // fontWeight: "600",
    fontSize: "18px",
    lineHeight: "130%",
    textAlign: "right",
  },
  ButtonsGrid: {
    display: "flex",
    gap: "10px",
    justifyContent: "right",
  },
  btn1: {
    paddingTop: "-1%",
    width: "118.66px",
    height: "47.28px",
    background: `linear-gradient(180deg, #00A4EF 0%, #474747 100%)`,
    borderRadius: " 5px",
    marginLeft: "20%",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "15px",
    lineHeight: "20px",
    color: "#fff",
    "&:hover": {
      background: "transparent",
      color: "#fff",
      border: "3px solid #00AFFF",
    },
  },
  btn2: {
    paddingTop: "-1%",
    width: "118.66px",
    height: "47.28px",
    background: `linear-gradient(180deg, #00A4EF 0%, #474747 100%)`,
    borderRadius: " 5px",
    marginLeft: "2.5%",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "15px",
    lineHeight: "20px",
    color: "#fff",
    "&:hover": {
      background: "transparent",
      color: "#fff",
      border: "3px solid #00AFFF",
    },
  },
}));

const TopBar = () => {
  const classes = useStyle();
  let history = useHistory();
  return (
    <Grid xs={12} className={classes.root}>
      <Grid xs={4} className={classes.ImageGrid}>
        <img
          src="images/TonyLogo.svg"
          alt=""
          onClick={() => history.push("/register")}
        />
      </Grid>
      <Grid item xs={4} className={classes.middleGrid}>
        <item>HOME</item>
        <item>PRICING</item>
        <item>ABOUT</item>
      </Grid>
      <Grid xs={4} className={classes.ButtonsGrid}>
        <Button
          className={classes.btn1}
          onClick={() => history.push("/register")}
        >
          Sign Up
        </Button>
        <Button className={classes.btn2} onClick={() => history.push("/login")}>
          Sign In
        </Button>
      </Grid>
    </Grid>
  );
};

export default TopBar;
