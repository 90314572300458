
import axios from "axios";
import {BASE_URL} from "../config/APICongig"



// wallet address save api
export const saveWalletAddress = async (add, token) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${BASE_URL}/metamaskWalletAdress?token=${token}`,
      data: {
        WalletAdress: add,
      },
    });
    return res;
  } catch (error) {
    return [];
  }
};
// get policy service
export const getPolicy = async () => {
  try {
    const res = await axios({
      method: "GET",
      url: `${BASE_URL}/getPrivacyPolicy`,
    });
    return res;
  } catch (error) {
    console.log("Error", error);
  }
};
// get terms and condition service
export const getTermsCondition = async () => {
  try {
    const res = await axios({
      method: "GET",
      url: `${BASE_URL}/getTermsAndConditions`,
    });
    return res;
  } catch (error) {
    console.log("Error", error);
  }
};
// get about us service
export const getAbout = async () => {
  try {
    const res = await axios({
      method: "GET",
      url: `${BASE_URL}/getAboutUs`,
    });
    return res;
  } catch (error) {
    console.log("Error", error);
  }
};
