import React, { useEffect, useState, useContext } from "react";
import "src/scss/main.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  Link,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Input,
  InputAdornment,
  IconButton,
  makeStyles,
  MenuItem,
} from "@material-ui/core";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { BsEnvelope, BsPerson } from "react-icons/bs";
import { AiOutlineLock, AiOutlineEye } from "react-icons/ai";
import Checkbox from "@material-ui/core/Checkbox";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Logo from "src/component/Logo";
import DatePicker from "react-datepicker";
import { Form, Formik } from "formik";
import * as yep from "yup";
import moment from "moment";
import axios from "axios";
import { AuthContext } from "src/context/Auth";
import TopBar from "src/layouts/LoginLayout/index.js";
import { useHistory, Link as RouterComponent } from "react-router-dom";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import CustomToast from "../../../component/customToast";
import { signUp } from "../../../service/auth";
import { Error } from "@material-ui/icons";
import { BASE_URL } from "../../../config/APICongig";
const useStyles = makeStyles((theme) => ({
  buttonbox: {
    width: "432.8px",
    height: "37.4px",
    marginLeft: "7%",
    background: "#00A4EF",
    borderRadius: " 7px",
    padding: "10px 18px",
    border: " 3px solid #00A4EF",
    [theme.breakpoints.only("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "14px",
    },
    "&:hover": {
      background: "#fff",
      color: "#00A4EF",
      border: " 3px solid #00A4EF",
    },
    "&:active": {
      background: "#fff",
      color: "#00A4EF",
      border: " 3px solid #00A4EF",
    },
  },
  title: {
    "& h3": {
      width: "637px",
      marginLeft: "-7%",
      fontWeight: "600",
      fontSize: "45px",
      fontFamily: "Inter",
      fontStyle: "normal",
      color: "#000000",
      lineHeight: "52px",
      "@media (max-width:767px)": {
        fontSize: "27px",
        lineHeight: "22px",
      },
      "@media (max-width:433px)": {
        fontSize: "27px",
        lineHeight: "28px",
      },
    },
  },
  date: {
    "& p": {
      marginLeft: "0px !important",
      fontSize: "12px !important",
    },
  },
  root: {
    width: "100%",

    backgroundColor: "#fff",
    "@media (min-width: 1326px)": {
      "& .MuiContainer-root": {
        paddingLeft: "0",
        paddingRight: "0",
      },
    },
  },
  textfiled1: {
    width: "202px",
    height: " 48px",
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "7px",
  },
  textfiled: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "7px",
    width: "434px",
    height: "48px",
    boxShadow: "none",
  },
  logosec: {
    "@media(min-width:1280px)": {
      display: "none",
    },
  },
  formboxes: {
    marginTop: "0px",
  },
  newbox: {
    color: "rgba(53, 99, 246, 1) ",
    textDecoration: "none",
    "&:hover": { textDecoration: "underline" },
  },
  title1: {
    fontFamily: "Inter",
    fontStyle: " normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#FFFFFF",
    padding: "16px 0px 5px 2px",
    
    

  },
  title2: {
    padding: "22px 0px 5px 36px",
    fontFamily: "Inter",
    fontStyle: " normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#FFFFFF",
  },
  checkbox: {
    // marginTop: "-1%",
    color: "#00A4EF",
    marginLeft: "7%",
    "& .MuiCheckbox-root": {
      color: "#00A4EF",
      paddingLeft: "0px",
    },
    "& .MuiTypography-body1": {
      color: "#000000",
    },
  },
}));
function Signup(props) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [phone, setPhone] = useState();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [showStates, setShowStates] = useState([]);
  const [btnText, setBtnText] = useState("CREATE AN ACCOUNT");
  const [agree, setAgree] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("");
  const [message, setMessage] = React.useState("");
  const handleClose = (x) => {
    setOpen(x);
  };
  const formInitialSchema = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const formValidationSchema = yep.object().shape({
    firstName: yep
      .string()
      // .matches(/^[A-Za-z]+$/,"Please enter valid name")
      .required("First Name is required."),
    lastName: yep.string().required("Last Name is required."),
    // .matches(/^[A-Za-z]+$/,"Please enter valid name"),
    email: yep
      .string()
      .required("Email is required.")
      .email("Please enter valid email address.")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please enter valid email."
      ),
    password: yep
      .string()
      .max(100, "100 charactors are allowed.")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,100}$/,
        "Password must be 8 to 100 character long with one uppercase, one lower case, a number, and a special character. "
      )
      .required("Password is required."),
    confirmPassword: yep
      .string()
      .required("Confirm password is required.")
      .oneOf([yep.ref("password"), null], "Password need to match."),
  });

  const handleFormSubmit = async (values) => {
    console.log(values, "values");
    setIsLoading(true);
    setBtnText("Creating....");
    try {
      if (agree) {
        const res = await axios.post(`${BASE_URL}/signup`, {
          firstname: values.firstName,
          lastname: values.lastName,
          email: values.email,
          password: values.password,
          confirm_Password: values.confirmPassword,
        });
        console.log(res, "api data");
        if (res.status === 200) {
          auth.setEndtime(moment().add(3, "m").unix());
          setIsLoading(false);
          setBtnText("CREATE AN ACCOUNT");
          console.log(res, "responsesdf");
          // if (res.data.success === true) {
          auth.setEndtime(moment().add(3, "m").unix());
          setIsLoading(true);
          setType("success");
          setOpen(true);
          setMessage("OTP sent successfully, Please check your email.");
          setTimeout(() => {
            history.push("/verify-otp");
          }, 1000);
          localStorage.setItem("email", values.email);
          setIsLoading(false);
          // calling Add notification function
        }

        // }
        else {
          setType("error");
          setIsLoading(false);
          setOpen(true);
          setMessage(res.repsonse.data.message);
        }
        return res;
      }
    } catch (error) {
      const err = error;
      console.log(err.response.status, "ERRORtest");
      // if (err.response.status === 500) {
      //   setType("error");
      //   setOpen(true);
      //   setMessage("Please enter valid email");
      // }

      if (
        err.response.data.message ===
        "Email is Already Register Please verify Email Address for Login OTP has been sent To Email"
      ) {
        auth.setEndtime(moment().add(3, "m").unix());
        setType("error");
        setIsLoading(false);
        setOpen(true);
        setMessage(
          "Email is Already Register Please verify Email Address for Login OTP has been sent To Email"
        );
        setTimeout(() => {
          history.push("/verify-otp");
        }, 1000);
        localStorage.setItem("email", values.email);
      } else if (
        err.response.data.message ===
        "OTP is Already Sent To Mail For Resend OTP Wait For 3 Min"
      ) {
        auth.setEndtime(moment().add(3, "m").unix());
        setType("error");
        setIsLoading(false);
        setOpen(true);
        setMessage("OTP is Already Sent To Mail For Resend OTP Wait For 3 Min");
        setTimeout(() => {
          history.push("/verify-otp");
        }, 1000);

        localStorage.setItem("email", values.email);
      } else if (err.response.data.message === "Email is already exist") {
        setType("error");
        setIsLoading(false);
        setOpen(true);
        setMessage("Email is Already Exist");
      }

      setIsLoading(false);
      setType("error");
      setOpen(true);
      setMessage("Please enter valid email");
      return err;
    }
  };

  const checkboxHandler = () => {
    setAgree(!agree);
    setIsLoading(false);
  };

  useEffect(() => {
    axios.get("/static/json/countries.json").then(function (response) {
      setCountries(response.data.countries);
      axios.get("/static/json/states.json").then(function (response) {
        setStates(response.data.states);
      });
    });
  }, []);

  const changeStateList = (name) => {
    const selectted = states.filter((cont) => {
      return cont.name === name;
    });
    if (selectted.length !== 0) {
      const contId = selectted[0].id;
    }
  };

  const changeState = (e) => {
    const name = e.target.value;
    changeStateList(name);
  };

  const changeCountryList = (name) => {
    const selectted = countries?.filter((cont) => {
      return cont.name === name;
    });
    const contId = selectted[0]?.id;

    const allState = states?.filter((state) => {
      return state.country_id === contId;
    });
    setShowStates(allState);
  };

  const changeCountry = (e) => {
    const name = e.target.value;
    changeCountryList(name);
  };
  const showToast = () => {
    setType("warn");
    setOpen(true);
    setMessage("Please accept Terms and Conditions and Privacy Policy");
  };
  console.log(agree, "agree");
  const exceptThisSymbols = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];

  return (
    <>
      {" "}
      <page title="Sign up">
        <div>
          <CustomToast
            type={type}
            handleClose={handleClose}
            open={open}
            message={message}
          />
        </div>
        <TopBar>
          <Grid className="d-flex height100">
            <Box className="loginForm">
              <Box className="signupBox">
                <Box className="signupbox">
                  <Formik
                    initialValues={formInitialSchema}
                    initialStatus={{
                      success: false,
                      successMsg: "",
                    }}
                    validationSchema={formValidationSchema}
                    onSubmit={(values) => handleFormSubmit(values)}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      values,
                      setFieldValue,
                    }) => (
                      <Form>
                        <Grid
                          container
                          direction={"column"}
                          style={{ marginTop: "-2%" }}
                        >
                          <Grid item>
                            <Box className={classes.logosec}>
                              <Logo width="110" style={{ cursor: "pointer" }} />
                            </Box>
                          </Grid>
                          <div
                            style={{
                              display: "flex",
                              padding: "0px 22px 0px 33px",
                              gap: "2rem",
                            }}
                          >
                            <Grid item mt={4}>
                              <Typography className={classes.title1}>
                                First name
                              </Typography>
                              <TextField
                                placeholder="Enter your first name"
                                onKeyDown={(e) =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                                variant="outlined"
                                fullWidth
                                size="small"
                                name="FirstName"
                                inputProps={{ maxLength: 256 }}
                                value={values.firstName}
                                error={Boolean(
                                  touched.firstName && errors.firstName
                                )}
                                onBlur={handleBlur("firstName")}
                                onChange={handleChange("firstName")}
                                autoComplete="off"
                                InputProps={{
                                  className: classes.textfiled1,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Box
                                        style={{
                                          borderRight: "0.5px solid #7A7A7A",
                                        }}
                                      >
                                        <BsPerson
                                          style={{
                                            marginLeft: "-2px",
                                            marginRight: "10px",
                                            width: "12px",
                                            color: "#594848",
                                            fontSize: "20px",
                                          }}
                                        />
                                      </Box>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <FormHelperText
                                error
                                style={{ fontSize: "12px", marginLeft: "3%" }}
                              >
                                {touched.firstName && errors.firstName}
                              </FormHelperText>
                            </Grid>
                            <Grid item mt={4}>
                              <Typography className={classes.title1}>
                                Last name
                              </Typography>
                              <TextField
                                onKeyDown={(e) =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                                placeholder="Enter your last name"
                                variant="outlined"
                                fullWidth
                                size="small"
                                name="LastName"
                                inputProps={{ maxLength: 256 }}
                                value={values.lastName}
                                error={Boolean(
                                  touched.lastName && errors.lastName
                                )}
                                onBlur={handleBlur("lastName")}
                                onChange={handleChange("lastName")}
                                autoComplete="off"
                                InputProps={{
                                  className: classes.textfiled1,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Box
                                        style={{
                                          borderRight: "0.5px solid #7A7A7A",
                                        }}
                                      >
                                        <BsPerson
                                          style={{
                                            marginLeft: "-2px",
                                            marginRight: "10px",
                                            width: "12px",
                                            color: "#594848",
                                            fontSize: "20px",
                                          }}
                                        />
                                      </Box>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <FormHelperText
                                error
                                style={{ fontSize: "12px", marginLeft: "3.5%" }}
                              >
                                {touched.lastName && errors.lastName}
                              </FormHelperText>
                            </Grid>
                          </div>
                          <Grid item mt={3}>
                            <Typography className={classes.title2}>
                              Email
                            </Typography>
                            <TextField
                              style={{
                                width: "434px",
                                height: "48px",
                                padding: " 0px 0px 0px 36px",
                              }}
                              placeholder="Enter your email"
                              type="text"
                              variant="outlined"
                              fullWidth
                              size="small"
                              name="email"
                              value={values.email}
                              inputProps={{ maxLength: 256 }}
                              error={Boolean(touched.email && errors.email)}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              autoComplete="off"
                              InputProps={{
                                className: classes.textfiled,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Box
                                      style={{
                                        borderRight: "0.5px solid #7A7A7A",
                                      }}
                                    >
                                      <BsEnvelope
                                        style={{
                                          marginLeft: "-2px",
                                          marginRight: "10px",
                                          width: "12px",
                                          color: "#594848",
                                          fontSize: "20px",
                                        }}
                                      />
                                    </Box>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <FormHelperText
                              error
                              style={{ fontSize: "12px", marginLeft: "7%" }}
                            >
                              {touched.email && errors.email}
                            </FormHelperText>
                          </Grid>

                          <Grid item>
                            <FormControl fullWidth>
                              <Box
                                style={{
                                  width: "100%",
                                  marginTop: "-0px",
                                  marginBottom: "17px",
                                }}
                              >
                                <Typography className={classes.title2}>
                                  Password
                                </Typography>
                                <TextField
                                  style={{ padding: " 0px 0px 0px 36px" }}
                                  className={classes.inputvalue}
                                  placeholder="Enter your password"
                                  size="small"
                                  inputProps={{ maxLength: 100 }}
                                  variant="outlined"
                                  autoComplete="new-password"
                                  fullWidth
                                  type={showPassword ? "text" : "password"}
                                  value={values.password}
                                  name="password"
                                  error={Boolean(
                                    touched.password && errors.password
                                  )}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  InputProps={{
                                    className: classes.textfiled,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() =>
                                            setShowPassword(!showPassword)
                                          }
                                          edge="end"
                                        >
                                          <Box className={classes.passsec}>
                                            {showPassword ? (
                                              <AiOutlineEye
                                                style={{
                                                  color: "#7A7A7A",
                                                  // color: "#7A7A7A",
                                                  fontSize: "18px",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              />
                                            ) : (
                                              <AiOutlineEyeInvisible
                                                style={{
                                                  color: "#7A7A7A",
                                                  // color: "#7A7A7A",
                                                  fontSize: "18px",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              />
                                            )}
                                          </Box>
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Box
                                          style={{
                                            borderRight: "0.5px solid #7A7A7A",
                                          }}
                                        >
                                          <AiOutlineLock
                                            style={{
                                              marginLeft: "-2px",
                                              marginRight: "10px",
                                              width: "14px",
                                              color: "#00A4EF",
                                              fontSize: "22px",
                                            }}
                                          />
                                        </Box>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <FormHelperText
                                  error
                                  style={{ fontSize: "12px", marginLeft: "7%" }}
                                >
                                  {touched.password && errors.password}
                                </FormHelperText>
                              </Box>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            style={{ marginBottom: "10px", marginTop: "-17px" }}
                          >
                            <FormControl fullWidth>
                              <Box
                                style={{ width: "100%" }}
                                className={classes.loginForm1}
                              >
                                <Typography className={classes.title2}>
                                  Confirm Password
                                </Typography>
                                <TextField
                                  style={{
                                    width: "434px",
                                    boxShadow: "none",
                                    height: "48px",

                                    padding: " 0px 0px 0px 36px",
                                  }}
                                  placeholder="Enter your password"
                                  size="small"
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{ maxLength: 100 }}
                                  value={values.confirmPassword}
                                  type={showPassword1 ? "text" : "password"}
                                  name="confirmPassword"
                                  // placeholder="Confirm your password"
                                  error={Boolean(
                                    touched.confirmPassword &&
                                      errors.confirmPassword
                                  )}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  InputProps={{
                                    className: classes.textfiled,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() =>
                                            setShowPassword1(!showPassword1)
                                          }
                                          edge="end"
                                        >
                                          <Box className={classes.passsec}>
                                            {showPassword1 ? (
                                              <AiOutlineEye
                                                style={{
                                                  color: "#7A7A7A",
                                                  // color: "#7A7A7A",
                                                  fontSize: "18px",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              />
                                            ) : (
                                              <AiOutlineEyeInvisible
                                                style={{
                                                  color: "#7A7A7A",
                                                  // color: "#7A7A7A",
                                                  fontSize: "18px",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              />
                                            )}
                                          </Box>
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Box
                                          style={{
                                            borderRight: "0.5px solid #7A7A7A",
                                          }}
                                        >
                                          <AiOutlineLock
                                            style={{
                                              marginLeft: "-2px",
                                              marginRight: "10px",
                                              width: "14px",
                                              color: "#00A4EF",
                                              fontSize: "22px",
                                            }}
                                          />
                                        </Box>
                                      </InputAdornment>
                                    ),
                                  }}
                                />

                                <FormHelperText
                                  error
                                  style={{ fontSize: "12px", marginLeft: "7%" }}
                                >
                                  {touched.confirmPassword &&
                                    errors.confirmPassword}
                                </FormHelperText>
                              </Box>
                            </FormControl>
                          </Grid>

                          <Grid
                            item
                            style={{
                              display: "inline",
                              marginLeft: "3%",
                              // marginTop: "2%",
                            }}
                          >
                            <Box className={classes.checkbox}>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                  <div>
                                    <FormControlLabel
                                      value="end"
                                      control={
                                        <Checkbox
                                          color="secondary"
                                          onChange={checkboxHandler}
                                          style={{
                                            Border: "1px solid #3563F6",
                                            borderRadius: "2px",
                                          }}
                                        />
                                      }
                                    />
                                    <label
                                      color="primary.main"
                                      variant="body1"
                                      style={{
                                        color: "#FFFFFF",
                                        paddingTop: "20px",
                                        marginLeft: "-15px",
                                        fontFamily: "Inter",
                                        fontStyle: "normal",
                                        fontWeight: "300",
                                        fontSize: "12px",
                                        lineHeight: "22px",
                                        // marginLeft:'7%'
                                      }}
                                      className={classes.TearmCondition}
                                    >
                                      I Accept&nbsp;
                                      <Link
                                        component={RouterComponent}
                                        to="/term"
                                        className={classes.newbox}
                                        style={{
                                          fontWeight: "700",
                                          color: "#00A4EF",
                                        }}
                                      >
                                        Terms & Conditions &nbsp;
                                      </Link>
                                      and
                                      <Link
                                        component={RouterComponent}
                                        to="/policy"
                                        className={classes.newbox}
                                        style={{
                                          fontWeight: "700",
                                          color: "#00A4EF",
                                        }}
                                      >
                                        &nbsp; Privacy Policy
                                      </Link>
                                    </label>
                                  </div>
                                </FormGroup>
                              </FormControl>
                            </Box>
                          </Grid>

                          <Grid item>
                            {agree == true ? (
                              <Button
                                variant="contained"
                                color="secondary"
                                type="submit"
                                className={classes.buttonbox}
                                disabled={isLoading}
                                onClick={handleSubmit}
                              >
                                Sign Up
                                {isLoading && <ButtonCircularProgress />}
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="secondary"
                                type="submit"
                                className={classes.buttonbox}
                                disabled={isLoading}
                                onClick={showToast}
                              >
                                Sign Up
                              </Button>
                            )}
                          </Grid>

                          <Grid item>
                            <Typography
                              color="primary.main"
                              variant="body1"
                              style={{
                                marginTop: "20px",
                                textAlign: "center",
                                color: "#FFFFFF",
                                fontfamily: "Inter",
                                fontstyle: "normal",
                                fontWeight: "300",
                                fontSize: "12px",
                                lineHeight: "22px",
                                letterSpacing: "0.06em",
                                paddingBottom: "25px",
                                paddingLeft: "22px",
                              }}
                            >
                              Already have an account?&nbsp;
                              <Link
                                component={RouterComponent}
                                to="/login"
                                style={{
                                  fontWeight: "bold",
                                  color: "#00A4EF",
                                }}
                              >
                                Sign In
                              </Link>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Box>
          </Grid>
        </TopBar>
      </page>
    </>
  );
}

export default Signup;
